$(document).on('ready turbolinks:load', function () {
  const labelWithTooltipSelector = '.label-with-description ~ input, .label-with-description ~ * input'

  initTooltips()

  $(document).on('focus', labelWithTooltipSelector, function () {
    $(this).parents('.form-group').find('.label-with-description').tooltip('show')
  })
  $(document).on('blur', labelWithTooltipSelector, function () {
    $(this).parents('.form-group').find('.label-with-description').tooltip('hide')
  })
  $(document).on('hidden.bs.tooltip', '.label-with-description', function () {
    const $label = $(this)
    const inputFocused = $label.parents('.form-group').find('input')[0].matches(':focus')

    if (inputFocused) $label.tooltip('toggle')
  })

  {
    const dashboard = document.querySelector('.dashboard')
    if (!dashboard) return

    const $sidebarTooltips = $('.sidebar-nav__middle a, .sidebar-nav__bottom a').tooltip({
      placement: 'left',
      trigger: 'hover',
      offset: 'auto, -215px',
    })

    global.sidebarTooltips = {
      enable: () => $sidebarTooltips.tooltip('enable'),
      disable: () => $sidebarTooltips.tooltip('disable')
    }

    if (dashboard.dataset.sidebarOpen === '') sidebarTooltips.disable()
    else sidebarTooltips.enable()
  }
});

$(document).on('click', '.show-password', function() {
  const $password = $('#user_password');

  if ($password.attr('type') === 'password') {
    $password.attr('type', 'text');
  } else {
    $password.attr('type', 'password');
  }
});

global.initTooltips = function (selector = '[data-toggle="tooltip"], .trix-button', options = {}) {
  if (!$.fn.tooltip) return

  hideAllTooltips()
  const tooltipStyles = () => `.tooltip { top: ${-parseInt(document.body.style.top || 0)}px!important; }`
  if (!global.tooltipStyle) {
    global.tooltipStyle = document.createElement('style')
    tooltipStyle.innerHTML = tooltipStyles()
    document.body.append(tooltipStyle)
  }

  const config = { attributes: true }
  const observer = new MutationObserver(mutations => mutations.forEach(mutation => {
    if (mutation.attributeName != 'style') return
    tooltipStyle.innerHTML = tooltipStyles()
  }))

  observer.observe(document.body, config)
  global.$tooltips = $(selector).tooltip({
    placement: 'top',
    trigger: 'hover',
    offset: 'auto, 5px',
    ...options
  })
}

global.hideAllTooltips = function () {
  $('[role="tooltip"]').add(global.$tooltips).tooltip('hide')
}
