require.context('../images', true);
import './font'
import './style'

global.$ = $
global.jQuery = jQuery
global.toastr = require('toastr/toastr')
global.select2 = require('select2/dist/js/select2.full')

import 'popper.js'
import 'bootstrap/js/dist/tooltip'

import 'javascripts/ui/select'
import 'javascripts/ui/textarea'
import 'javascripts/ui/tooltips'
import 'javascripts/ui/flash'
