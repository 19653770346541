$(document).on('ready turbolinks:load ajax:success', initTextarea);

function initTextarea() {
  const $textareas = $('textarea');

  $textareas.each(function () {
    const $textarea = $(this);
    const initHeight = $textarea.innerHeight();
    const computed = window.getComputedStyle(this);
    const borderWidths = parseInt(computed.getPropertyValue('border-top-width') + computed.getPropertyValue('border-bottom-width'), 10);
    const paddingSizes = parseInt(computed.getPropertyValue('padding-top') + computed.getPropertyValue('padding-bottom'), 10);

    resize();
    $textarea.on('keyup', resize);

    function resize() {
      $textarea.css({
        height: 'inherit',
        maxHeight: initHeight,
      })
        .promise()
        .done(function () {
          const textareaHeight = borderWidths + $textarea[0].scrollHeight + paddingSizes;

          if (textareaHeight < 1000) {
            $textarea.css({
              height: `${textareaHeight < initHeight ? initHeight : textareaHeight}px`,
              maxHeight: 'none',
            });
          }
        });
    };
  });
};

window.initTextarea = initTextarea
