$(document).on('ready turbolinks:load', () => initSelect());

$(document).on('click', '.clear-select-options', function(e){
  e.preventDefault();
  $(this).closest('.form-group').find('option').prop('selected', false);
  $(this).closest('.form-group').find('select').trigger('change');
});

global.initSelect = function (selector, options = {}, isSelect) {
  if (!selector) selector = 'form'
  let $selects = isSelect ? $(selector) : $(selector).find('select')

  $selects.each(function () {
    const isSlim = this.classList.contains('slim-select')
    const props = this.dataset
    const config = {
      allowClear: false,
      ...options,
      ...props,
      ...isSlim && {
        containerCssClass: 'select2--slim',
        dropdownCssClass: 'select2-dropdown--slim'
      }
    }

    if (props.withTagSelection && !$(this).hasClass('tag-option-added')) {
      $(this).addClass('tag-option-added')
      const tags = JSON.parse(props.withTagSelection)
      for (const tag in tags) {
        $(this).prepend(`<option class='select-all-options' value='tagged-with-${tags[tag].join('|')}'>Tagged as ${tag}</option>`)
      }
    }

    if (props.selectAll == '1' && !$(this).hasClass('all-option-added')) {
      $(this).addClass('all-option-added')
      $(this).prepend("<option class='select-all-options' value='all'>Select All</option>")
    }

    if (props.selectProviderGoals == '1' && !$(this).hasClass('all-option-added')) {
      $(this).addClass('all-option-added')
      $(this).prepend("<option class='select-all-options' value='all-dental'>Select All Dental KPIs</option>")
      $(this).prepend("<option class='select-all-options' value='all-hygiene'>Select All Hygiene KPIs</option>")
    }
    $(this).select2(config)

    $(this).on('select2:select', function (e) {
      let data = e.params.data.id;

      $(this).parent().find('.select2-search__field').val('');
      $(this).parent().find('.select2-search__field').trigger($.Event('input', { which: 13 }));

      if (props.selectAll == '1' || props.selectProviderGoals == '1') {
        if (data == 'all') {
          $(this).find('option[value=all]').prop('selected', false);
          $(this).find('option[value!=all]').prop('selected', 'selected');
          $(this).find('option:contains(Tagged as)').prop('selected', false);
          $(this).trigger('change');
        }
        if (data == 'all-dental') {
          $(this).find('option:contains(Dental -)').prop('selected', 'selected');
          $(this).find('option[value=all-dental]').prop('selected', false);
          $(this).trigger('change');
          $(this).select2('close');
        }
        if (data == 'all-hygiene') {
          $(this).find('option:contains(Hygiene -)').prop('selected', 'selected');
          $(this).find('option[value=all-hygiene]').prop('selected', false);
          $(this).trigger('change');
          $(this).select2('close');
        }
      }

      if (props.withTagSelection && data.includes('tagged-with-')) {
        const tags = data.replace("tagged-with-","").split('|')
        for (tagId in tags) {
          $(this).find(`option[value=${tags[tagId]}]`).prop('selected', 'selected');
        }
        $(this).find(`option[value="${data}"]`).prop('selected', false);
        $(this).trigger('change');
        $(this).select2('close');
      }
    });

    if (props.selectAll == '1' || props.selectProviderGoals == '1') {
      $(this).closest('.form-group').find('.select2-selection').after("<a class='clear-select-options' href='#'>clear</a>");
    }
  });
}

global.initSelectize = function () {
  $("select.selectize-select").on("change", function () {
    const selectizedSelect = $(this).next(".selectize-select")
    selectizedSelect.removeClass('is-invalid');
    selectizedSelect.next('.error-message').remove();
  });
  $("select.selectize-select").selectize({
    create: function(input, callback) {
      const inputEl = this.$input;
      const url = this.$input.data('url');
      const field = this.$input.data('field');
      const key = this.$input.data('key');
      let params = {};

      params[key] = {};
      params[key][field] = input;

      $.ajax({
        method: 'POST',
        url: url,
        data: params,
        success: function(response) {
          console.log(response);
          const selectizedSelect = $(inputEl).next(".selectize-select")
          if (response.status == "unprocessable_entity") {
            selectizedSelect.addClass('is-invalid')

            if (selectizedSelect.next('.error-message').length === 0) {
              $(`<div class="error-message">${response.errors[0]}</div>`).insertAfter(selectizedSelect)
            }
            callback({ value: null, text: null })
          } else {
            selectizedSelect.removeClass('is-invalid')
            selectizedSelect.next('.error-message').remove()
            callback({ value: response.id, text: input })
          }
        }
      })
    },
    sortField: "text"
  });
}

global.filterOptions = function (selector = null, optionsArray = [], ignoreValues = []) {
  if (!selector || optionsArray.length < 1) return
  const $selects = $(selector).filter(':visible')
  const selectedItems = [...$selects.find(':selected')].map(item => +item.value)
  const filteredOptions = optionsArray.reduce((acc, item) => selectedItems.includes(item[0])
    ? acc : acc.concat([item]), [])

  $selects.not($(this)).each(function () {
    const $select = $(this)
    const $options = $select.find('option[value]').not('[value=""]').not((_, item) => ignoreValues.includes(+item.value))
    const currentOption = optionsArray.find(([value]) => value == +this.value)
    const [currentOptionValue] = currentOption || []
    const newOptions = filteredOptions.concat(currentOption ? [currentOption] : [])
    const newOptionsHTML = newOptions.sort().map(([value, label]) =>
      `<option value='${value}' ${currentOptionValue === value ? 'selected' : ''}>${label}</option>`
    ).join('')

    $options.remove()
    $select.append(newOptionsHTML)
  })
}

global.userAvatarForSelect = function (user) {
  if (!user.id) return user.text
  const $user = $(
    `<div class='option-with-avatar'>${user.element.dataset.avatar}<span>${user.text}</span></div>`
  );
  return $user;
};
